<template>
    <div>
        <!-- Breadcrumbs -->
        <v-container v-if="smAndUp">
            <v-row>
                <v-col>
                    <v-breadcrumbs
                        :items="breadCrumbs"
                        divider=">"
                    ></v-breadcrumbs>
                </v-col>
            </v-row>
        </v-container>

        <!-- お客様の声 -->
        <v-container>
            <v-row justify="center" :class="smAndUp ? 'mb-15' : null">
                <v-col cols="auto" align="center">
                    <h1><heading>20代・A.Yさん</heading></h1>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="6" :class="smAndUp ? null : 'order-2'">
                    <v-img :src="imageGuest1" contain> </v-img>
                </v-col>
                <v-col cols="12" md="6" class="align-self-center">
                    <div
                        :class="smAndUp ? 'text-h4' : 'text-h6'"
                        class="font-weight-thin"
                        v-html="smAndUp ? leadPc : leadSp"
                    ></div>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="6" class="order-2">
                    <v-img :src="imageGuest2" contain> </v-img>
                </v-col>
                <v-col cols="12" md="6">
                    <div class="font-weight-thin text-body-1">
                        <div class="question mb-4">
                            Q1:
                            指導を受けようと思ったきっかけはなんですか？(体験しようとしたきっかけ）
                        </div>
                        <div>
                            <span class="answer">A: </span>
                            以前1人で大手スポーツジムに通っていたが続かず…再開しようと思っても再開できず、1人でできないなら予約していけるように、より効率良くトレーニングしたいと思いました。
                            あと他のパーソナルジムに行くよりもかなりリーズナブルなことに加えて、家が近かったことが決め手になりました。
                        </div>
                        <div class="question my-4">
                            Q2:
                            指導を受ける前の体はどんな状態でしたか？どんな生活でしたか？
                        </div>
                        <div>
                            <span class="answer">A: </span>
                            バスト、ウエスト、ヒップが同じくらいの太さだったと思います。
                            だから服を選ぶのが大変。入るのを探す感じでした。
                            それから、外食時に何も考えずに好きな物だけ食べていました。締めのデザートは別腹ですよね？（笑）。
                            運動は...しなかったです。
                        </div>
                    </div>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="6" :class="smAndUp ? null : 'order-2'">
                    <v-img :src="imageGuest3" contain> </v-img>
                </v-col>
                <v-col cols="12" md="6">
                    <div class="font-weight-thin text-body-1">
                        <div class="question mb-4">
                            Q3:
                            結果が出た後（体が変わった後）見た目や生活はどう変わりましたか？
                        </div>
                        <div>
                            <span class="answer">A: </span>
                            顔が小さくなりました！全体的に小さくなったと周りの人からも言われるようになりましたし。
                            食事は好きなものを食べますが、カロリーを考えながら食べています。
                            たくさん食べる日の前後は少なくして調整するようにもしています。体が軽くなりちょっとした運動が苦にならなくなりました。
                            性格も以前より明るくなり、怒ることも減りました。
                        </div>
                        <div class="question my-4">
                            Q4:
                            REVOISTの指導を受けたほうがいい人（オススメしたいひと）、逆に受けないほうが良い・必要ない人はどんなひとでしょうか？
                        </div>
                        <div>
                            <span class="answer">A: </span>
                            今のままで満足している人にはオススメしませんね。
                            でも、逆に変わりたいと思っている人にはオススメします。
                            特に、変わりたいと思っているけどどうやって変わればいいかわからない人にはオススメです！
                        </div>
                    </div>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="6" class="order-2">
                    <v-img :src="imageGuest4" contain> </v-img>
                </v-col>
                <v-col cols="12" md="6">
                    <div class="font-weight-thin text-body-1">
                        <div class="question mb-4">
                            Q5:
                            指導を受けていなかったらどうなっていたと思いますか？
                        </div>
                        <div>
                            <span class="answer">A: </span>
                            もっと太っていた（笑）。
                            今よりアクティブな生活や考え方になれなかったと思います。
                        </div>
                        <div class="question my-4">
                            Q6:
                            指導を受けようか迷っている人へメッセージをお願いします。
                        </div>
                        <div>
                            <span class="answer">A: </span>
                            カラダづくりのプロトレーナー達が目的に合ったトレーニングを提供してくれるので、ここは変わりたい自分になる近道です。
                            迷っているならとりあえず体験してほしいですね。
                        </div>
                    </div>
                </v-col>
            </v-row>

            <v-row justify="center">
                <v-col md="8">
                    <link-tag compName="Testimonial"
                        >お客様の声一覧に戻る</link-tag
                    >
                </v-col>
            </v-row>
        </v-container>

        <footer-contents></footer-contents>
    </div>
</template>

<script>
import Heading from '@/components/Heading.vue'
import FooterContents from '@/components/FooterContents.vue'
import LinkTag from '@/components/LinkTag.vue'
export default {
    mounted() {
        document.title = `${this.$route.meta.title} | パーソナルトレーニングジム【REVOISTレボイスト】`
    },
    components: {
        Heading,
        FooterContents,
        LinkTag,
    },

    computed: {
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
        breadCrumbs() {
            return [
                {
                    text: 'トップ',
                    disabled: false,
                    href: './',
                },
                {
                    text: 'お客様の声',
                    disabled: false,
                    href: 'testimonial',
                },
                {
                    text: '20代・A.Yさん',
                    disabled: false,
                    href: '',
                },
            ]
        },

        imageGuest1() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_testimonial/4B7A8691.png'
                : 'static/sp_testimonial–1/testimonial01_img01.png'
        },
        imageGuest2() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_testimonial–1/グループ 17.png'
                : 'static/sp_testimonial–1/testimonial01_img02.png'
        },
        imageGuest3() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_testimonial–1/4B7A8786.png'
                : 'static/sp_testimonial–1/testimonial01_img03.png'
        },
        imageGuest4() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_testimonial–1/4B7A8760.png'
                : 'static/sp_testimonial–1/testimonial01_img04.png'
        },

        leadPc() {
            return '顔が小さくなって、<br />小さな服も着られるように<br />なりました！'
        },
        leadSp() {
            return '顔が小さくなって、小さな服も着られるようになりました！'
        },
    },
}
</script>

<style lang="scss" scoped>
.question {
    color: #1a0dab;
}
.answer {
    color: #c30013;
}
</style>
